import gettext from 'airborne/gettext';
import identity from 'lodash/identity';
import difference from 'lodash/difference';
import {CharField, Field} from 'midoffice/newforms/fields-stateless';
import {combineValidators, errorMessage} from 'midoffice/newforms/helpers';


const NAME_RE = /^[ ]*[A-Za-z]+( [A-Za-z]+)*[ ]*$/;
const LAST_MIN_LEN = 2;
const CC_FIRST_MAX_LEN = 25;
const CC_LAST_MAX_LEN = 40;

const SR_RE = /^[\w\s\-\,\.\*=:]*$/;
const COMPANY_RE = /^[\w\s\-\,\.\*=]*$/;
const SR_MIN_LEN = 5;

function valdiateRe(re, errorFn, value) {
    return (!value || re.test(value))
        ? null
        : errorFn();
}

export function makeReField(baseField, re, errorFn, cleanFn=identity) {
    return {
        ...baseField,
        validate: combineValidators(
            CharField.validate,
            (value)=> valdiateRe(re, errorFn, cleanFn(value)),
        ),
    };
}

export function emptyMessage() {
    return gettext('This field is required');
}


function maxLengthMessage({maxLength}) {
    return gettext('Please enter no more than {maxLength} characters.', {maxLength});
}

function minLengthMessage({minLength}) {
    return gettext('Please enter at least {minLength} characters.', {minLength});
}

function nameField(errorFn, minLength=null, maxLength=null) {
    return {
        ...makeReField(CharField, NAME_RE, errorFn),
        minLength,
        maxLength,
        emptyMessage,
        minLengthMessage,
        maxLengthMessage,
    };
}

function srFormatMessage() {
    return gettext('Invalid special requests. Only uppercase and lowercase latin letters, numbers, spaces, dashes, periods, asterisks and commas are allowed.');
}

function dashFormatMessage() {
    return gettext('Underscores are not allowed in special request for this provider.');
}

export function specialRequestField(maxLength, provider) {
    const SRField = makeReField(CharField, SR_RE, srFormatMessage);
    return {
        ...SRField,
        minLength: SR_MIN_LEN,
        maxLength,
        minLengthMessage,
        maxLengthMessage,
        validate: combineValidators(
            SRField.validate,
            provider !== 'hcorpo'
                ? (value) => value?.includes('_') ? dashFormatMessage() : null
                : () => {}
        ),
    } ;
}

export function specialCompanyField(maxLength) {
    return {
        ...makeReField(CharField, COMPANY_RE, srFormatMessage),
        maxLength,
        minLengthMessage,
        maxLengthMessage,
    } ;
}

export function collectionDeliveryField(minLength) {
    return {
        ...makeReField(CharField, COMPANY_RE, srFormatMessage),
        minLength,
        minLengthMessage,
        maxLengthMessage,
    } ;
}

export const FirstNameField = nameField(
    ()=> gettext('Please enter a first name that contains only letters')
);
export const MiddleNameField = nameField(
    ()=> gettext('Please enter a middle name that contains only letters')
);
export const LastNameField = nameField(
    ()=> gettext('Please enter a last name that contains only letters'),
    LAST_MIN_LEN,
);
export const FirstNameCapitalizedField = nameField(
    ()=> gettext('Please enter a First Name that contains only letters')
);
export const LastNameCapitalizedField = nameField(
    ()=> gettext('Please enter a Last Name that contains only letters'),
    LAST_MIN_LEN,
);
export const CCFirstNameField = nameField(
    ()=> gettext('Please enter a first name that contains only letters'),
    null,
    CC_FIRST_MAX_LEN,
);
export const CCLastNameField = nameField(
    ()=> gettext('Please enter a last name that contains only letters'),
    LAST_MIN_LEN,
    CC_LAST_MAX_LEN,
);


function removeSpaces(value) {
    // do not clean in case of no numbers there
    // so a non-number string will be validated later
    // instead of being accepted as an empty one
    if (!/\d/.test(value)) {
        return value;
    }
    return value.replace(/[+\-\s(-)]/gi, '');
}

const PHONE_RE = /^\d{10,}$/;
export const PhoneField = makeReField(
    CharField,
    PHONE_RE,
    ()=> gettext('Invalid phone number'),
    removeSpaces,
);

const POSTCODE_RE = /^[\w\s-]{1,10}$/;
export const PostCodeField = makeReField(
    CharField,
    POSTCODE_RE,
    ()=> gettext('Invalid Postal Code. Postal code should consist of 1 to 10 uppercase, lowercase letters, numbers, spaces, dashes and underscores.')
);

const ZIP_RE = /^\d{5}$/;
export const ZipCodeField = makeReField(
    CharField,
    ZIP_RE,
    ()=> gettext('Invalid ZIP Code. US ZIP Code should consist of 5 numbers only.')
);

const ADDRESS_MAX_LEN = 50;
export const AddressField = {
    ...CharField,
    maxLength: ADDRESS_MAX_LEN,
    maxLengthMessage,
};

const ALPHANUM_RE = /^[a-z0-9]+$/i;
const INVALID_ALPHANUM_MSG = ()=> gettext(
    'Ensure this field has only alphanumeric characters'
);
const INVALID_LENGTH_MESSAGE = () => gettext('Loyalty Card Number must be not bigger than 20 characters');

export const LoyaltyCardField = {
    ...Field,
    validate(value) {

        if (this.isRequired && !value) {
            return errorMessage(this.emptyMessage);
        }

        const isSelected = value && value.includes('\u001E');
        if (value && !isSelected && !ALPHANUM_RE.test(value)) {
            return errorMessage(INVALID_ALPHANUM_MSG);
        }

        if (value && !isSelected && value.length > 20) {
            return errorMessage(INVALID_LENGTH_MESSAGE);
        }

        return null;
    }
};

export function usingZipCode(countryCode) {
    // List of US subdivisions usung ZIP Codes:
    // https://en.wikipedia.org/wiki/ISO_3166-2:US#Subdivisions_included_in_ISO_3166-1
    return ['US', 'AS', 'GU', 'MP', 'PR', 'UM', 'VI'].includes(countryCode);
}

export function validateZipOrPostCode(countryField, postalCodeField, value) {
    const country = value[countryField];
    const zip = value[postalCodeField];
    const error = usingZipCode(country)
        ? ZipCodeField.validate(zip)
        : PostCodeField.validate(zip);
    return error
        ? {[postalCodeField]: error}
        : null;
}

export function hasStates(country) {
    return country === 'US' || country === 'CA' || country === 'AU';
}

export function validateState(countryCodeField, provinceField, value) {
    const country = value[countryCodeField];
    const state = value[provinceField];
    return (!state && hasStates(country))
        ? {
            [provinceField]: emptyMessage(),
        }
        : null;
}

export function skipOrRequire(value, keys) {
    const missingValues = difference(keys, Object.keys(value)).map((key) => [key, null]);
    const valueEntries = [...Object.entries(value), ...missingValues];

    const fields = valueEntries.filter(([key]) => keys.includes(key));
    const emptyFields = fields.filter(([, value]) => !value);

    const isAnyFieldHasValue = fields.length !== emptyFields.length;

    if (emptyFields.length > 0 && isAnyFieldHasValue) {
        return Object.fromEntries(
            emptyFields.map(([key]) => [key, emptyMessage()])
        );
    }

    return null;
}
