import {searchForm} from 'airborne/homepage2/helpers/search';
import {HOTELS_API_URL} from 'airborne/hotels/api';
import settings from 'airborne/settings';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';

import air from './air';

function specialRatesParams(codes) {
    return codes && codes.length ? {'loyalty_program_codes': codes} : {};
}

function getRatesRetryParams(state, searchId) {
    const {profile} = searchForm(state, 0);
    return {
        'configuration_id': getSelectedConfiguration(state) || profile.company,
        'search_id': searchId,
        'date_format_type': settings.USER['date_format'],
        'include_more_details': true,
    };
}

function getRatesParams(state, hotelId) {
    const {
        profile,
        dest,
        i18n,
        hotels: {ratesSid},
    } = searchForm(state, 0);
    const searchId = ratesSid[hotelId];

    return searchId
        ? getRatesRetryParams(state, searchId)
        : {
              'hotel_id': hotelId,
              'configuration_id': getSelectedConfiguration(state) || profile.company,
              'checkin': dest.dates.min,
              'checkout': dest.dates.max,
              'guest_count': dest.guests,
              'currency': i18n.currency,
              ...specialRatesParams(dest['special_rates']),
              'timeout': 30,
              'dont_wait_for_results': true,
              'date_format_type': settings.USER['date_format'],
              'include_more_details': true,
          };
}

export async function ratesRequest(state, hotelId) {
    const {
        rates,
        'search_id': searchId,
        'data_is_complete': completed,
        warnings,
        'chunks_requested': chunksRequested,
        'chunks_received': chunksReceived,
        'hotel_id': hotelID,
    } = await air('GET', HOTELS_API_URL.getRates, getRatesParams(state, hotelId));

    return {rates, searchId, completed, warnings, chunksRequested, hotelID, chunksReceived};
}
