import isEqual from 'lodash/isEqual';
import settings from 'airborne/settings';
import {getURLParameter} from 'airborne/utils/params';
import omit from 'lodash/omit';


export function keyForInheritance({treeName, section, toMatrix = false}) {
    const policyGroupId = getURLParameter('policy_group_id');
    if (policyGroupId && !toMatrix) {
        return `${treeName}/${section}/${policyGroupId}`;
    }
    return `${treeName}/${section}`;
}

export function keyForSettings({id, treeName, section, system}) {
    const policyGroupId = getURLParameter('policy_group_id');
    if (policyGroupId) {
        return `${treeName}/${id}/${section}/${system}/${policyGroupId}`;
    }
    return `${treeName}/${id}/${section}/${system}`;
}

export function keyForCompanyInfo({id, treeName}) {
    return `${treeName}/${id}`;
}
function isRoot(id, list) {
    return list[id] && !list[id].parent;
}

function inheritanceEnabled(matrix, id, system) {
    const tabs = matrix[id] || {};
    return tabs[system] !== false;
}

function shouldInheritHelper(id, system = 'all', matrix, list) {
    let currentSystem = 'all';

    if (system === 'all') {
        id = list[id] && list[id].parent;
    }

    while (id && !isRoot(id, list) && inheritanceEnabled(matrix, id, currentSystem)) {
        if (currentSystem === 'all') {
            id = list[id] && list[id].parent;
            currentSystem = system;
        }
        else {
            currentSystem = 'all';
        }
    }
    if (!id || !list[id]) {
        return null;
    }

    const entity = list[id];

    return {id, system: currentSystem, title: entity.title};
}

export function getDefaultSystem(treeName, section) {
    if (treeName === 'agencies') {
        return 'all';
    }
    return settings.COMPANY_SECTIONS[section]?.defaultSystem;
}

export function hasSystem(treeName, section, system) {
    if (treeName === 'agencies') {
        return true;
    }
    const screen = settings.COMPANY_SECTIONS[section];
    return screen && screen.systems.includes(system);
}

export function allowedRoute(treeName, section, system) {
    const SECTIONS = treeName === 'companies' ? settings.COMPANY_SECTIONS : settings.AGENCY_SECTIONS;
    section = SECTIONS[section];
    if (!section) {
        return false;
    }

    if (!system) {
        return true;
    }
    if (treeName === 'agencies') {
        return true;
    }

    return section.systems.includes(system);
}

export function shouldInherit(state, {treeName, section, system, id}) {
    const key = keyForInheritance({treeName, section});
    const matrix = state.inheritance.hash[key];
    if (!matrix) {
        return null;
    }

    return shouldInheritHelper(id, system, matrix, state[treeName].list);
}

export function inherits(state, {treeName, section, system = 'all', id}) {
    const policyGroupId = getURLParameter('policy_group_id');

    const key = keyForInheritance({treeName, section});
    const matrix = state.inheritance.hash[key];
    if (!matrix) {
        return {enabled: false};
    }

    const {list} = state[treeName];
    const defaultTabs = {
        aft: false,
        all: false,
        obt: false,
        mob: false,
    };
    const {edit = {}, ...tabs} = matrix[id] || {};
    const tabsToCheck = policyGroupId ? {
        ...defaultTabs,
        ...tabs,
    } : tabs;
    const edited = Boolean(edit[system]);
    const enabled = edited ? edit[system].flag : tabsToCheck[system] !== false;
    const parent = shouldInheritHelper(id, system, matrix, list);
    const defaultSystem = getDefaultSystem(treeName, section);
    const possible = (list[id] && list[id].parent) || system !== defaultSystem;
    return {enabled, parent, possible, edited};
}

export function getSettings({settings}, query) {
    const key = keyForSettings(query);
    const data = settings.hash[key];
    const policyGroupInheritance = settings.hash[key]?.['policyGroupInheritance'];
    const initial = settings.initial[key];
    const errors = settings.errors[key];
    const failed = settings.failed[key];
    const loading = settings.loading;

    const editedData = omit(data, ['policyGroupInheritance', 'policyGroupInfo']);
    const initialData = omit(initial, ['policyGroupInheritance', 'policyGroupInfo']);
    const edited = !isEqual(editedData, initialData);

    return {data: data ? omit(data, 'policyGroupInheritance') : data, policyGroupInheritance, initial, errors, loading, failed, edited};
}

export function getCompanyInfo({settings={}}, query) {
    const key = keyForCompanyInfo(query);
    const {companyInfoHash={}} = settings;
    return companyInfoHash[key];
}

export function getConstants({settings={}}, section) {
    const {constants} = settings;
    return constants[section];
}

export function edited(state, query) {
    const {edited: inheritanceEdited} = inherits(state, query);
    const {edited: settingsEdited} = getSettings(state, query);
    return inheritanceEdited || settingsEdited;
}

export function isTravelport(gds) {
    return ['apollo', 'galileo'].includes(gds);
}

export function needSettings(treeName, section) {
    return !(treeName === 'companies' && section === 'config-export');
}
