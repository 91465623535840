import {combineReducers} from 'redux';
import {STEPS} from 'airborne/air/exchange/Modification/types';
import * as actionTypes from '../actionTypes';
import * as availabilityTypes from 'airborne/air/store/availability/actionTypes';
import * as pricingTypes from 'airborne/air/store/pricing/actionTypes';
import * as searchTypes from 'airborne/air/store/fare_search/actionTypes';

const initialStep = STEPS.FORM;
const step = (state = initialStep, {type, payload}) => {
    switch (type) {
        case actionTypes.AIR_EXCHANGE_CLOSE:
        case actionTypes.AIR_EXCHANGE_SET_LOADING:
            return initialStep;
        case actionTypes.SET_CURRENT_STEP:
            return payload.step;

        case availabilityTypes.AIR_AVAILABILITY_TO_SEARCH:
            return STEPS.AVAILABILITY;
        case pricingTypes.AIR_PRICING_TO_PRICING:
            return STEPS.PRICING;
        case searchTypes.AIR_TO_SEARCH_RESULT:
            return STEPS.FARE_SEARCH;
        case searchTypes.AIR_TO_CHECKOUT:
            return STEPS.CHECKOUT;
        case 'GO_TO_HOMEPAGE':
            return STEPS.FORM;

        default:
            return state;
    }
};

const initialCurrentBooking = null;
const currentBooking = (state = initialCurrentBooking, {type, payload}) => {
    switch (type) {
        case actionTypes.AIR_EXCHANGE_CLOSE:
            return initialCurrentBooking;
        case actionTypes.SET_CURRENT_BOOKING:
            return payload.booking;
        default:
            return state;
    }
};

const active = (state = false, {type}) => {
    switch (type) {
        case actionTypes.AIR_EXCHANGE_OPEN:
            return true;
        case actionTypes.AIR_EXCHANGE_CLOSE:
            return false;
        default:
            return state;
    }
};

const initialLoading = false;
const loading = (state = initialLoading, {type, payload}) => {
    switch (type) {
        case actionTypes.AIR_EXCHANGE_CLOSE:
            return initialLoading;
        case actionTypes.AIR_EXCHANGE_SET_LOADING:
            return payload;
        default:
            return state;
    }
};

const initialManualExchange = false;
const manualExchange = (state = initialManualExchange, {type, payload}) => {
    switch (type) {
        case actionTypes.AIR_SET_MANUAL_EXCHANGE:
            return payload;
        default:
            return state;
    }
};

const initialBeforeExchangeCurrency = null;
const beforeExchangeCurrency = (state = initialBeforeExchangeCurrency, {type, payload}) => {
    switch (type) {
        case actionTypes.SET_BEFORE_EXCHANGE_CURRENCY:
            return payload;
        default:
            return state;
    }
};

const params = combineReducers({
    step,
    currentBooking,
    active,
    loading,
    manualExchange,
    beforeExchangeCurrency
});

export default params;
