import settings from 'airborne/settings';
import {parse} from 'airborne/types';

import air from './air';

import 'airborne/search2/type';
import {searchForm} from 'airborne/homepage2/helpers/search';
import {apiError} from 'airborne/search2/helpers/apiError';
import {getRate, getDetails} from 'airborne/search2/helpers/rates';

import {handleApiError} from './air';

import {showModal} from 'airborne/store/modules/header/actions/modal';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';
import {HOTELS_API_URL} from 'airborne/hotels/api';

export default function getRateDetails(hotelId, rateKey, rethrow = false) {
    return async function getRateDetailsD(dispatch, getState) {
        const state = getState();
        const {loaded, loading} = getDetails(state, hotelId, rateKey);
        if (loaded || loading) {
            return true;
        }
        const {profile, dest} = searchForm(state, 0);
        const rate = getRate(state, hotelId, rateKey);
        const params = {
            'configuration_id': getSelectedConfiguration(state) || profile.company,
            'hotel_id': hotelId,
            'rate_key': rateKey,
            'guest_count': dest.guests,
            'date_format_type': settings.USER['date_format'],
        };
        dispatch({type: 'RATE_DETAILS_LOADING', id: hotelId, rateKey});

        try {
            const raw = await air('GET', HOTELS_API_URL.getRateDetails, params);
            const data = parse('rateDetails', raw);
            dispatch({type: 'RATE_DETAILS_LOADED', data, id: hotelId, rateKey});
            return data;
        } catch (response) {
            try {
                handleApiError(response);
                dispatch({type: 'RATE_DETAILS_ABORT', id: hotelId, rateKey});
            } catch (error) {
                const details = apiError(response) || null;
                const {provider = null} = rate || {};
                dispatch(showModal('norates', {details, provider}));
                dispatch({type: 'RATE_DETAILS_FAIL', id: hotelId, rateKey});
            }
            if (rethrow) throw response;
        }
    };
}
