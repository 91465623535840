import {createSelector} from 'reselect';
import intersection from 'lodash/intersection';
import uniqBy from 'lodash/uniqBy';
import some from 'lodash/some';
import concat from 'lodash/concat';
import compact from 'lodash/compact';
import difference from 'lodash/difference';
import {getFareGroups} from 'airborne/air/store/fare_search/selectors/fareGroups';
import {getFareFamilyGroups} from 'airborne/air/store/fare_search/selectors/fareFamilies';
import {getDestination} from 'airborne/store/modules/homepage/selectors/homepage';
import {getPricingFares} from 'airborne/air/store/pricing/selectors';
import {getTabs} from 'airborne/air/fare_search/helpers/fareRules';
import {AIR_TRIP_TYPES} from 'airborne/homepage2/types';
import {swapLocations} from 'airborne/air/fare_search/helpers';
import {getSeparatedTicketsFlightPrices} from 'airborne/air/store/fare_search/selectors/flightPrice';

const getFareRulesContainer = state => state.air.fareRules;

const getFareRules = state => getFareRulesContainer(state).data;

export const getFareRulesByKey = (state, fareGroupKey) => getFareRules(state)[fareGroupKey];

export const getIsFareRulesLoading = state => getFareRulesContainer(state).loading;

export const getFareRulesTabs = createSelector(
    getFareGroups,
    getFareFamilyGroups,
    getPricingFares,
    (state) => getSeparatedTicketsFlightPrices(state),
    getFareRulesByKey,
    (state, fareGroupKey) => fareGroupKey,
    (fareGroups, fareFamilyGroups, pricingFares, flightPrices, fareRules, fareGroupKey) => {
        // BE returns data we need to build this UI in 2 different requests
        // with different data shape.
        // fareRules are from `fare_rules` and has only `fareBasisCode` for
        // identification and also some of the fareRules might be missing comparing to
        // `fareGroup.fareComponents` which has segmentIdRef for identification
        if (!fareRules) {
            return [];
        }

        // At first we had fare groups only from fare_search response,
        // so any of the fareGroupKey had to be in one of these groups.
        // But then we started to get additional groups on checkout (Fare Families) from fare_families response,
        // so we have to search inside them as well.
        // + And now we have even more groups from pricing endpoint
        const allFareGroups = compact(concat(fareGroups, fareFamilyGroups, pricingFares, flightPrices));

        const fareGroupByKey = allFareGroups.find(group => group.fareGroupKey === fareGroupKey);

        const fareGroupBasisCodes = fareGroupByKey.fareComponents.map((fareComponent) => fareComponent.fareBasisCode);
        const fareRulesBasisCodes = fareRules.map(({fareBasisCode}) => fareBasisCode);
        const fareBasisCodesMismatch = difference(fareRulesBasisCodes, fareGroupBasisCodes).length;

        const fareGroup = fareBasisCodesMismatch
            ? allFareGroups.find(
                fareGroup => fareGroup.fareComponents
                    .map(fareComponent => fareComponent.fareBasisCode)
                    .every(fareBasisCode => fareRulesBasisCodes.includes(fareBasisCode))
            )
            : fareGroupByKey;

        // adding missing rules
        const allFareRules = fareGroup.fareComponents.map(({fareBasisCode, segmentIds}) => {
            const existingRule = fareRules.find(rule => (
                rule.fareBasisCode === fareBasisCode
                && intersection(segmentIds, rule.segmentIds).length
            ));
            return existingRule || {fareBasisCode, segmentIds};
        });

        let fareRulesByOD;

        // Fare rules might be seperated randomly, and we need to figure out
        // which fare rule is appliable for each OriginDestination.
        // ie: we have roundtrip with segments A->B->C->B->A.
        // Some possible FareRules:
        // |------------------|-----------------------|
        // |  recieved:       |  by orign destination |
        // |------------------|-----------------------|
        // |  ABC, CBA        |  [ABC], [CBA]         |
        // |  AB, BC, CB, BA  |  [AB, BC], [CB, BA]   |
        // |  ABCB, BA        |  [ABCB], [ABCB, BA]   |
        // |------------------|-----------------------|
        if (fareGroup.originDestinations.length === 1) {
            fareRulesByOD = [allFareRules];
        }
        else {
            fareRulesByOD = fareGroup.originDestinations.map(OD => {
                // handling both cases when segments are in the root of OD and in flightOptions
                // fare_search response && fare_families response
                const fareSegments = OD.segments || OD.flightOptions[0].segments;

                // get segmentIdRefs and fareBasisCodes only for current Origin Destination
                const ids = fareSegments.map(s => s.segmentIdRef);
                const fareBasisCodes = fareGroup.fareComponents
                    .filter(({segmentIds}) => intersection(segmentIds, ids).length)
                    .map(fc => fc.fareBasisCode);

                // filter out fare rules for other OriginDestinations
                return uniqBy(allFareRules.filter(({fareBasisCode, segmentIds}) => (
                    fareBasisCodes.includes(fareBasisCode)
                    && intersection(segmentIds, ids).length
                )), 'fareBasisCode');
            });
        }

        return getTabs().map(tab => ({
            ...tab,
            fareBasisCodesByOD: fareRulesByOD
                .map(fareRules => fareRules.map(({fareBasisCode}) => fareBasisCode)),
            dataByOD: fareRulesByOD.map(fareRules => fareRules.map(fareRule => ({
                data: fareRule[tab.key],
                fareBasisCode: fareRule.fareBasisCode,
            }))),
        })).filter(tab => some(tab.dataByOD, dataArr => some(dataArr, item => item.data)));
    }
);

export const getODTabs = createSelector(
    getDestination,
    (destination) => {
        const {originDestinations, tripType} = destination;
        const [firstDestination] = originDestinations;

        return (tripType === AIR_TRIP_TYPES.ROUND_TRIP
            ? [firstDestination, swapLocations(firstDestination)]
            : originDestinations
        ).map(({pickUp, dropOff}) => `${pickUp.iataCode} → ${dropOff.iataCode}`);
    }
);

