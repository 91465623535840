import {createSelector} from 'reselect';
import isNil from 'lodash/isNil';
import {
    getFlightPriceData,
    getSelectedFlightPrice,
} from './flightPrice';
import {
    getUniqueFareKey,
    extractFareKeys,
    isSameFares,
    getBaseFareDifference,
    mergeFlightPriceAttributes,
    extractLowestBrandedFare
} from 'airborne/air/fare_search/helpers';
import {isAirSeparatedTicketsMode} from "airborne/air/store/pricing/selectors";

const getFareFamilies = state => state.air.fareFamilies;

export const getFareFamiliesData = state => getFareFamilies(state).data;
export const getFareFamiliesSelection = state => getFareFamilies(state).selected;
export const getSeparatedTicketsSelection = state => getFareFamilies(state).selected.separatedTickets;
export const getFareFamiliesErrors = state => getFareFamilies(state).errors;

export const getFareFamilyKey = (state, ticketIndex) => {
    const {fareGroupKey, flightOptionKeys} = isNil(ticketIndex)
        ? getFareFamiliesSelection(state)
        : getSeparatedTicketsSelection(state)[ticketIndex];

    return (
        fareGroupKey &&
        flightOptionKeys &&
        getUniqueFareKey({fareGroupKey, flightOptionKeys})
    );
};

export const getOriginalFare = state => {
    const flightPriceData = getFlightPriceData(state);
    const originalFareKey = getFareFamilyKey(state);
    const originalFare = flightPriceData[originalFareKey];

    const fareFamiliesData = getFareFamiliesData(state);
    const fareFamiliesKey = getFareFamilyKey(state);
    const fareFamiliesFares = fareFamiliesData[fareFamiliesKey];

    return mergeFlightPriceAttributes(originalFare, fareFamiliesFares);
};

export const isFareFamilyGroupsFetched = createSelector(
    getFareFamiliesData,
    getFareFamilyKey,
    (data, storeKey) => storeKey && Object.keys(data).includes(storeKey)
);

export const getFareFamilyGroups = state => {
    const fareFamiliesData = getFareFamiliesData(state);
    const storeKey = getFareFamilyKey(state);
    const originalFare = getOriginalFare(state);

    return (
        storeKey
        && fareFamiliesData[storeKey]
            // We are filtering out original fare from the fare families response
            ?.filter(fare => !isSameFares(fare, originalFare))
    );
};

export const getLowestBrandedFare = createSelector(
    getFareFamilyGroups,
    getOriginalFare,
    (fareFamilyGroups, originalFare) => extractLowestBrandedFare(fareFamilyGroups, originalFare)
);

const getFareFamilyGroupsLength = state =>
    getFareFamilyGroups(state)?.length || 0;

export const useFareFamilyGroups = state =>
    getFareFamilyGroupsLength(state) > 0;

export const getSelectedFareFamilyGroup = createSelector(
    getFareFamiliesSelection,
    getFareFamilyGroups,
    getOriginalFare,
    ({fareFamilyKey}, fareFamilyGroups, originalFare) => {
        return fareFamilyKey
            ? fareFamilyGroups?.find(group => group.fareGroupKey === fareFamilyKey)
            : originalFare;
    }
);

export const getSelectedFareFamilyGroupKeys = createSelector(
    getSelectedFareFamilyGroup,
    group => extractFareKeys(group)
);

const getFlightPriceOfSelectedFareFamilyGroup = createSelector(
    getSelectedFareFamilyGroupKeys,
    getFlightPriceData,
    (fareKeys, flightPriceData) => {
        const [fareGroupKey, flightOptionKeys] = fareKeys;
        const storeKey = getUniqueFareKey({fareGroupKey, flightOptionKeys});
        return flightPriceData[storeKey];
    }
);

export function getCheckoutFareGroup(state) {
    const useFareFamilies = useFareFamilyGroups(state);

    if (useFareFamilies) {
        return (
            getFlightPriceOfSelectedFareFamilyGroup(state) ||
            getSelectedFareFamilyGroup(state)
        );
    }

    return getSelectedFlightPrice(state);
}

// ===== DYNAMIC SELECTORS =====

export const isFareFamilyGroupSelected = (state, fareGroupKey) =>
    getSelectedFareFamilyGroup(state)?.fareGroupKey === fareGroupKey;


// ===== MAKE SELECTORS =====

const makeGetFareFamilyGroupByKey = fareGroupKey =>
    createSelector(
        getFareFamilyGroups,
        groups => groups?.find(group => group.fareGroupKey === fareGroupKey)
    );

export const makeGetFareFamilyPriceDifference = fareGroupKey => {
    const getFareFamilyGroup = makeGetFareFamilyGroupByKey(fareGroupKey);

    return createSelector(
        getOriginalFare,
        getFareFamilyGroup,
        // We have sorted fare groups from lowest to highest price
        (originalFare, group) => getBaseFareDifference(group, originalFare)
    );
};

export const getSeparatedTicketOriginalFare = (state, ticketIndex) => {
    const originalFareKey = getFareFamilyKey(state, ticketIndex);
    const flightPriceData = getFlightPriceData(state);
    return flightPriceData[originalFareKey];
}

export const getSeparatedTicketFareFamilies = (state, ticketIndex) => {
    const fareKey = getFareFamilyKey(state, ticketIndex);
    const fareFamiliesData = getFareFamiliesData(state);
    const flightPriceData = getFlightPriceData(state);

    const selection = getSeparatedTicketsSelection(state);
    const {fareFamilyKey: selectedFareFamilyKey} = selection[ticketIndex];

    const originalFare = flightPriceData[fareKey];
    const originalFareSelected = !selectedFareFamilyKey;

    const fareFamilyGroups = fareFamiliesData[fareKey]?.filter(fare => {
        const {fareGroupKey} = fare;
        // remove duplicated original fare from branded fare options if original fare is selected
        if (originalFareSelected) {
            return !isSameFares(fare, originalFare);
        }

        return fareGroupKey !== selectedFareFamilyKey;
    }) || [];

    const isOriginalFareMissing = !fareFamiliesData[fareKey]?.some((fare) => isSameFares(fare, originalFare));
    // add original fare to branded fare options if some branded fare is selected,
    // but original fare is missing in the fare families data
    if (!originalFareSelected && isOriginalFareMissing) {
        return [originalFare].concat(fareFamilyGroups);
    }

    return fareFamilyGroups;
}

export const getSeparatedTicketSelectedFareFamily = (state, ticketIndex) => {
    const selection = getSeparatedTicketsSelection(state);
    const fareKey = getFareFamilyKey(state, ticketIndex);
    const fareFamiliesData = getFareFamiliesData(state);
    const flightPriceData = getFlightPriceData(state);

    const {fareFamilyKey} = selection[ticketIndex];
    const fareFamilyGroups = fareFamiliesData[fareKey];

    return fareFamilyKey
        ? fareFamilyGroups?.find(group => group.fareGroupKey === fareFamilyKey)
        : mergeFlightPriceAttributes(flightPriceData[fareKey], fareFamilyGroups);
}

export const getSeparatedTicketLowestBrandedFare = (state, ticketIndex) => {
    const fareKey = getFareFamilyKey(state, ticketIndex);
    const fareFamiliesData = getFareFamiliesData(state);
    const fareFamilyGroups = fareFamiliesData[fareKey];
    const originalFare = getSeparatedTicketOriginalFare(state, ticketIndex);
    return extractLowestBrandedFare(fareFamilyGroups, originalFare);
};

export function getSeparatedTicketsCheckoutFareGroups(state) {
    if (!isAirSeparatedTicketsMode(state)) {
        return [getCheckoutFareGroup(state)];
    }

    return [
        getSeparatedTicketSelectedFareFamily(state, 0),
        getSeparatedTicketSelectedFareFamily(state, 1),
    ];
}
