import * as types from '../actionTypes';
import air from 'airborne/store/modules/search_hotels/actions/air';
import {getAirRequestParams} from 'airborne/air/store/fare_search/selectors';
import timeout from 'airborne/search2/helpers/timeout';
import {showError} from 'airborne/store/modules/search_hotels/actions';
import {apiError} from 'airborne/common/apiError';
import {toSearch} from 'airborne/air/store/fare_search/actions/navigation';
import {sendRecentAirSearch} from 'airborne/air/store/fare_search/actions/recentSend';
import {parseList} from 'airborne/types';
import {getAirSearchType, getDestFormValue} from 'airborne/store/modules/homepage/selectors/homepage';
import {isAir} from 'airborne/store/modules/homepage/selectors/product';
import browserHistory from 'airborne/browserHistory';
import {prefillCheckout} from './prefillCheckout';
import {isRestrictedCountryError} from 'airborne/air/fare_search/helpers/apiError';
import normalize from 'airborne/search2/helpers/normalize';
import SentryAdapter from 'airborne/sentryAdapter';

const faresLoaded = data => ({type: types.AIR_FARES_LOADED, data});

export const setPolicyRules = data => ({type: types.AIR_SET_POLICY_RULES, data});
export const addPolicyRules = data => ({type: types.AIR_ADD_POLICY_RULES, data});

export const parseFares = (response) => {
    const ret = parseList('fareGroups', response?.fare_groups || [])
        .sort((a, b) => {
            const checkByCurrentlyBooked = ({flightOptions}) => flightOptions.some(FO => FO.currentlyBooked);
            const aBooked = a.originDestinations.some(checkByCurrentlyBooked);
            const bBooked = b.originDestinations.some(checkByCurrentlyBooked);

            return aBooked === bBooked ? 0 : aBooked ? -1 : 1;
        });

    window.fares = ret;

    return ret;
};

export const parsePolicyRules = (response) => normalize(response?.policy_rules || [])

function startNewSearch(destination) {
    return function startNewSearchD(dispatch) {
        dispatch({type: types.AIR_START_SEARCH, destination});
    };
}

const WAIT_SWITCH = 2000;

function catch404(response) {
    return function catch404D(dispatch) {
        if (response?.status === 404) {
            isRestrictedCountryError(response)
                && dispatch(showError(apiError(response)[0], 'restrictedDestination'));

            return dispatch(faresLoaded([]));
        }
        throw response;
    }
}

export function makeFareSearch(requestParams, destinationForm) {
    return function fareSearchD(dispatch) {
        dispatch(startNewSearch(destinationForm));
        dispatch(prefillCheckout(destinationForm, false));

        dispatch({type: types.AIR_FARES_LOADING});
        return air('POST', '/air/fare_search/', requestParams)
            .then(response => {
                dispatch(setPolicyRules(parsePolicyRules(response)));
                return dispatch(faresLoaded(parseFares(response)));
            })
            .catch(response => dispatch(catch404(response)))
            .catch(error => {
                if (error instanceof Error) {
                    SentryAdapter.captureException(error);
                }
                dispatch({type: types.AIR_FARES_FAIL});
                dispatch(showError(apiError(error), 'search'));
            });
    };
}

export function searchAir() {
    return async function searchAirD (dispatch, getState) {
        sendRecentAirSearch(getState());
        const requestParams = getAirRequestParams(getState());
        const destinationForm = getDestFormValue(getState());
        const fareSearchPromise = dispatch(makeFareSearch(requestParams, destinationForm));
        await Promise.race([fareSearchPromise, timeout(WAIT_SWITCH)]);
        dispatch(toSearch());
        const airSearchType = getAirSearchType(getState());
        if (airSearchType === 'availability') {
            browserHistory.push('/ui/air_availability/');
        }
        else {
            browserHistory.push('/ui/air/search/');
        }
        return await fareSearchPromise;
    };
}

export function reloadAirSearch() {
    return function reloadAirSearchD(dispatch, getState) {
        const state = getState();
        const onTheSearchPage = location.pathname.includes('/ui/air_availability') || location.pathname.includes('/ui/air/search');
        return isAir(state) && onTheSearchPage ? dispatch(searchAir()): Promise.resolve();
    };
}
