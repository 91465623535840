import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import BootstrapButton from 'react-bootstrap/Button';

export default class Button extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        progress: PropTypes.bool,
        children: PropTypes.any,
        onClick: PropTypes.func,
        bsStyle: PropTypes.oneOf([
            'primary',
            'secondary',
            'link',
            'inverse',
            'danger',
            'warning',
            'success',
            'info',
            'light',
            'dark',
        ]),
        bsSize: PropTypes.oneOf(['lg', 'sm']),
        disabled: PropTypes.bool,
        href: PropTypes.string,
        testId: PropTypes.string,
    };

    static defaultProps = {
        className: '',
        progress: false,
        bsStyle: 'secondary',
    };

    render() {
        const {progress, children, bsStyle: variant, bsSize: size, testId, ...props} = this.props;
        const className = classnames(this.props.className, {
            'btn--in-progress': progress,
        });

        return (
            <BootstrapButton data-testid={testId} variant={variant} size={size} {...props} className={className}>
                <div className="btn__text">{children}</div>
                <div className="btn__spinner" />
            </BootstrapButton>
        );
    }
}
