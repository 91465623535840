import every from 'lodash/every';

import {searchForm} from 'airborne/homepage2/helpers/search';
import {HOTELS_API_URL} from 'airborne/hotels/api';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';
import {dropEmpty} from 'midoffice/helpers/urlParams';

import air from './air';

function anySid(availSid, ids) {
    const sid = availSid[ids[0]];
    return sid && every(ids, id => availSid[id] === sid) ? sid : null;
}

function availabilityParams(state, ids) {
    const {
        profile,
        dest,
        hotels: {availSid},
        currency,
    } = searchForm(state, 0);
    const searchId = anySid(availSid, ids);
    return searchId
        ? {
              'configuration_id': getSelectedConfiguration(state) || profile.company,
              'search_id': searchId,
          }
        : {
              'configuration_id': getSelectedConfiguration(state) || profile.company,
              'checkin': dest.dates.min,
              'checkout': dest.dates.max,
              'guest_count': dest.guests,
              'currency': currency,
              'loyalty_program_codes': dest['special_rates'] || null,
              'timeout': 30,
              'dont_wait_for_results': true,
              'hotels': ids,
          };
}

export async function chunkRequest(state, ids) {
    const params = availabilityParams(state, ids);

    const {
        'hotels_data': data,
        'search_id': searchId,
        'data_is_complete': completed,
        'warnings': warnings,
        'rate_target': rateTarget,
        'chunks_requested': chunksRequested,
        'chunks_received': chunksReceived,
    } = await air('GET', HOTELS_API_URL.getAvailability, dropEmpty(params));

    if (completed && Object.keys(data).length === 0) {
        throw new Object({fin: true, body: {message: warnings}});
    }

    return {data, searchId, completed, rateTarget, chunksRequested, chunksReceived};
}
