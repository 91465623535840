import sha1 from 'crypto-js/sha1';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import {dropEmptyDeep} from 'airborne/helpers/emptyDeep';
import {AIR_PRODUCTS, PROVIDERS} from 'midoffice/companies/sections/VpaSettings/static';

const OMITTED_FIELDS = {
    'air': ['extra_validity_days_before_reservation'],
    'general_vpa': ['allow_non_vpa_cards', 'extra_validity_days', 'extra_validity_days_before_reservation'],
    'cars': ['vcc_deployment'],
};

const removeRedundantFields = (data, product, prefix = '') => {
    const omittedFields = (OMITTED_FIELDS[product] || []).map(field => prefix + field);
    return omit(data, omittedFields);
};

function getProviderData(name, provider) {
    const providerBillingAddress = provider['billing_address'] || {};
    const VPA_SETTINGS_PROVIDERS_NAME_KEY = 'vpa_settings:providers:' + name;
    const commonFields = {
        [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':api_key']: provider['api_key'] || null,
        [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':api_url']: provider['api_url'] || null,
        [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':is_enabled']: provider['is_enabled'] || false,
        [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':password']: provider['password'] || null,
        [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':billing_address:city']: providerBillingAddress['city'] || null,
        [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':billing_address:address']: providerBillingAddress['address'] || null,
        [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':billing_address:state_code']: providerBillingAddress['state_code'] || null,
        [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':billing_address:postal_code']:
            providerBillingAddress['postal_code'] || null,
        [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':billing_address:country_code']:
            providerBillingAddress['country_code'] || null,
    };
    const confermaFields =
        name !== 'conferma'
            ? {}
            : {
                [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':agent_id']: provider['agent_id'] || null,
                [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':booker_id']: provider['booker_id'] || null,
                [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':consumer']: provider['consumer'] || null,
            };
    const troovoFields =
        name !== 'troovo'
            ? {}
            : {
                [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':api_auth_url']: provider['api_auth_url'] || null,
            };
    const itelyaFields =
        name !== 'itelya'
            ? {}
            : {
                [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':card_provider']: provider['card_provider'] || null,
                [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':bi_api_url']: provider['bi_api_url'] || null,
                [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':bi_api_key']: provider['bi_api_key'] || null,
                [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':bi_password']: provider['bi_password'] || null,
                [VPA_SETTINGS_PROVIDERS_NAME_KEY + ':hotel_service_codes']: provider['hotel_service_codes'] || [],
            };

    return {
        ...commonFields,
        ...confermaFields,
        ...troovoFields,
        ...itelyaFields,
    };
}

function replaceClientIdOnUniqueId(productCardPools, providerCardPools = []) {
    return productCardPools.map(productClientId => {
        const cardPool = providerCardPools.find(
            ({'vpa_client_id': providerClientId}) => productClientId === providerClientId
        );
        return cardPool.id;
    });
}

function getCardPools(name, provider, product, providerCardPools) {
    const activeProviderCardPools = product?.card_pools?.[provider];

    const updatedArray = replaceClientIdOnUniqueId(activeProviderCardPools, providerCardPools[provider]);
    return {
        ['vpa_settings:' + name + ':card_pools:' + provider]: updatedArray || [],
    };
}

function parsePaymentOption(product) {
    const provider = product['payment_option']?.length > 1 ? ['payment_or_guarantee'] : product['payment_option'];
    return provider ? provider[0] : null;
}

function getProductData(name, product, providersListNames, providerCardPools) {
    const VPA_SETTINGS_NAME_KEY = 'vpa_settings:' + name;
    const data = {
        ...providersListNames.reduce((acc, provider) => {
            return {...acc, ...getCardPools(name, provider[0], product, providerCardPools)};
        }, {}),
        [VPA_SETTINGS_NAME_KEY + ':extra_validity_days']: product['extra_validity_days'] || null,
        [VPA_SETTINGS_NAME_KEY + ':extra_validity_days_before_reservation']:
            product['extra_validity_days_before_reservation'] || null,
        [VPA_SETTINGS_NAME_KEY + ':financial_partner_data']: product['financial_partner_data'] || [],
    };

    return removeRedundantFields(data, name, VPA_SETTINGS_NAME_KEY + ':');
}

const shift = Date.now();
function getUniqueId(el) {
    return sha1(JSON.stringify(el) + shift).toString();
}

export function parse(data) {
    const {'agency:vpa_settings': vpaSettings,  'air_financial_partner_data_choices': airFinancialPartnerDataChoices,
        'hotels_financial_partner_data_choices': hotelsFinancialPartnerDataChoices,
        'cars_financial_partner_data_choices': carsFinancialPartnerDataChoices,
        'common_financial_partner_data_choices': commonFinancialPartnerDataChoices,} = data;
    const {hotels = {}, air = {}, providers = {}, general = {}} = vpaSettings;
    const {conferma = {}} = providers;
    const providersListNames = Object.keys(providers).map(provider => [provider, provider]);

    // adding unique id to the card pool
    const confermaCardPools = !isEmpty(conferma)
        ? conferma['card_pools']
            .map(el => ({id: getUniqueId(el), ...el}))
        : [];
    const providerCardPools = {
        conferma: confermaCardPools,
    };
    return {
        ...getProductData('hotels', hotels, providersListNames, providerCardPools),
        ['vpa_settings:hotels:booking_issues_email']: hotels['booking_issues_email'] || null,
        ['vpa_settings:hotels:payment_option']: parsePaymentOption(hotels),
        'vpa_settings:hotels:default_payment_instructions': hotels['default_payment_instructions'] || null,
        'vpa_settings:hotels:billing_phone': hotels['billing_phone'] || {},

        ...getProductData('air', air, providersListNames, providerCardPools),
        ...getProductData('general_vpa', general, providersListNames, providerCardPools),
        ...getProviderData('conferma', conferma),

        ['vpa_settings:providers:conferma:card_pools']: confermaCardPools,

        'vpa_settings:air:financial_partner_data_choices': airFinancialPartnerDataChoices || [],
        'vpa_settings:hotels:financial_partner_data_choices': hotelsFinancialPartnerDataChoices || [],
        'vpa_settings:cars:financial_partner_data_choices': carsFinancialPartnerDataChoices || [],
        'vpa_settings:general_vpa:financial_partner_data_choices': commonFinancialPartnerDataChoices || [],

        'vpa_settings:general_vpa:financial_partner_data': general['financial_partner_data'] || [],
    };
}

function replaceUniqueIdOnClientId(productCardPools, providerCardPools) {
    const list = productCardPools.map(productCardPoolId => {
        const cardPool = providerCardPools.find(({id: providerCardPoolId}) => productCardPoolId === providerCardPoolId);
        return cardPool && cardPool['vpa_client_id'];
    });
    return dropEmptyDeep(list);
}
function addFlagToCardPool(list) {
    return (
        list &&
        list.map(cardPool => {
            return omit(
                {
                    'bcd_card_pool': false,
                    ...cardPool,
                },
                ['id']
            );
        })
    );
}

function normalizeCardPools(data, product) {
    const vpaProvider = PROVIDERS[0][0];
    const providerCardPools = data['vpa_settings:providers:' + vpaProvider + ':card_pools'];
    if (isEmpty(providerCardPools)) {
        return {
            'conferma': [],
        };
    }

    const confermaList = data['vpa_settings:' + product + ':card_pools:conferma'];
    return {
        'conferma': !isEmpty(confermaList)
            ? replaceUniqueIdOnClientId(confermaList, data['vpa_settings:providers:conferma:card_pools'])
            : [],
    };
}

function normalizeProduct(data, product) {
    const output = {
        'allow_non_vpa_cards': data['vpa_settings:' + product + ':allow_non_vpa_cards'],
        'card_pools': normalizeCardPools(data, product),
        'vpa_provider': data['vpa_settings:' + product + ':vpa_provider'],
        'extra_validity_days': data['vpa_settings:' + product + ':extra_validity_days'],
        'extra_validity_days_before_reservation':
            data['vpa_settings:' + product + ':extra_validity_days_before_reservation'],
        'is_enabled': data['vpa_settings:' + product + ':is_enabled'],
        'vcc_deployment': data['vpa_settings:' + product + ':vcc_deployment'],
        'financial_partner_data': data['vpa_settings:' + product + ':financial_partner_data'],
    };

    return removeRedundantFields(output, product);
}

function normalizeProviderData(data, provider) {
    const providerCountryCode = data['vpa_settings:providers:' + provider + ':billing_address:country_code'];
    const commonFields = {
        'api_key': data['vpa_settings:providers:' + provider + ':api_key'],
        'api_url': data['vpa_settings:providers:' + provider + ':api_url'],
        'card_pools': addFlagToCardPool(data['vpa_settings:providers:' + provider + ':card_pools']),
        'is_enabled': data['vpa_settings:providers:' + provider + ':is_enabled'],
        'password': data['vpa_settings:providers:' + provider + ':password'],
        'billing_address': {
            'city': data['vpa_settings:providers:' + provider + ':billing_address:city'],
            'address': data['vpa_settings:providers:' + provider + ':billing_address:address'],
            'state_code': data['vpa_settings:providers:' + provider + ':billing_address:state_code'],
            'postal_code': data['vpa_settings:providers:' + provider + ':billing_address:postal_code'],
            'country_code': providerCountryCode || null,
        },
    };
    const confermaFields =
        provider !== 'conferma'
            ? {}
            : {
                'agent_id': data['vpa_settings:providers:' + provider + ':agent_id'],
                'booker_id': data['vpa_settings:providers:' + provider + ':booker_id'],
                'consumer': data['vpa_settings:providers:' + provider + ':consumer'],
            };

    return {
        ...commonFields,
        ...confermaFields,
    };
}

export function serialize(data) {
    return {
        'agency:vpa_settings': {
            'hotels': {
                ...normalizeProduct(data, 'hotels'),
                'booking_issues_email': data['vpa_settings:hotels:booking_issues_email'],
                'default_payment_instructions': data['vpa_settings:hotels:default_payment_instructions'],
            },
            'air': {
                ...normalizeProduct(data, 'air'),
            },
            'providers': {
                'conferma': normalizeProviderData(data, 'conferma'),
            },
            'general': normalizeProduct(data, 'general_vpa'),
        },
    };
}
